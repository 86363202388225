<template>
  <div class="listing">
    <div class="title">
      <h2>青点互动第三方信息共享清单</h2>
    </div>
    <div>
      为保障青点互动相关功能的实现与应用安全稳定的运行，我们可能会接入由合作方提供的软件开发包（SDK）、应用程序接口（API）、应用程序插件等代码或通过其他合作方式，以实现相关目的。
    </div>
    <div>
      我们会对获取信息的合作方的代码进行严格的安全监测，以保护数据安全。
    </div>
    <div>
      我们对接入的相关合作方在目录中列明，合作方具体的处理情况请参见合作方的隐私政策或服务协议。
    </div>
    <div>
      请注意，合作方的代码可能因为其版本升级、策略调整等原因导致数据处理类型存在一定变化，请以其公示的官方说明为准。
    </div>
    <br />

    <div>
      <h3>内嵌SDK名单</h3>
    </div>
    <div>(1) SDK名称：QQ SDK</div>
    <div>第三方公司名称：腾讯科技（深圳）有限公司</div>
    <div>共享信息名称：设备标识信息、网络信息</div>
    <div>使用目的：第三方授权登录、内容分享</div>
    <div>使用场景：用户使用QQ账号授权登录；用户分享内容至QQ客户端时</div>
    <div>共享方式：SDK本机采集</div>
    <div>
      第三方隐私链接：<a
        class="agreement"
        href="https://wiki.connect.qq.com/qq"
        target="_Self"
        >https://wiki.connect.qq.com/qq</a
      >
    </div>
    <br />

    <div>(2) SDK名称：微信 SDK</div>
    <div>第三方公司名称：深圳市腾讯计算机系统有限公司</div>
    <div>共享信息名称：设备标识信息、网络信息</div>
    <div>使用目的：第三方授权登录、内容分享</div>
    <div>使用场景：用户使用微信账号授权登录时；用户分享内容至微信客户端时</div>
    <div>共享方式：SDK本机采集</div>
    <div>
      第三方隐私链接：<a
        class="agreement"
        href="https://support.weixin.qq.com/cgi-bin/mmsupportacctnodeweb-bin/pages/RYiYJkLOrQwu0nb8"
        target="_Self"
        >https://support.weixin.qq.com/cgi-bin/mmsupportacctnodeweb-bin/pages/RYiYJkLOrQwu0nb8</a
      >
    </div>
    <br />

    <!-- <div>(3) SDK名称：微信支付 SDK</div>
    <div>第三方公司名称：财付通支付科技有限公司</div>
    <div>共享信息名称：设备标识信息、网络信息</div>
    <div>使用目的：帮助用户在应用内完成支付</div>
    <div>使用场景：用户使用微信支付时</div>
    <div>共享方式：SDK本机采集</div>
    <div>
      第三方隐私链接：<a
        class="agreement"
        href="https://support.weixin.qq.com/cgi-bin/mmsupportacctnodeweb-bin/pages/RYiYJkLOrQwu0nb8"
        target="_Self"
        >https://support.weixin.qq.com/cgi-bin/mmsupportacctnodeweb-bin/pages/RYiYJkLOrQwu0nb8</a
      >
    </div>
    <br /> -->

    <!-- <div>(4) SDK名称：友盟PUSH</div>
    <div>
      第三方公司名称：北京锐讯灵通科技有限公司、友盟同欣（北京）科技有限公司、浙江阿里巴巴云计算有限公司、北京缔元信互联网数据技术有限公司等
    </div>
    <div>
      共享信息名称：设备标识符（如IMEI、MEID、IMSI、Android
      ID、Serial），WLAN接入点
    </div>
    <div>使用目的：推送信息</div>
    <div>使用场景：向用户推送消息时</div>
    <div>共享方式：SDK本机采集</div>
    <div>
      第三方隐私链接：<a
        class="agreement"
        href="https://developer.umeng.com/docs/147377/detail/209997"
        target="_Self"
        >https://developer.umeng.com/docs/147377/detail/209997</a
      >
    </div>
    <br /> -->

    <div>(3) SDK名称：极光一键登录</div>
    <div>第三方公司名称：深圳市和讯华谷信息技术有限公司</div>
    <div>
      共享信息名称：手机号码、设备标识信息（IMSI、IMEI、ICCID、IDFV）、网络信息（IP地址）、设备参数及系统信息（设备类型、设备型号、设备品牌、操作系统）、应用特征信息（应用程序版本、应用程序名称）
    </div>
    <div>使用目的：手机号码一键登录</div>
    <div>使用场景：移动用户使用一键登录时</div>
    <div>共享方式：SDK本机采集</div>
    <div>
      第三方隐私链接：<a
        class="agreement"
        href="https://www.jiguang.cn/license/privacy"
        target="_Self"
        >https://www.jiguang.cn/license/privacy</a
      >
    </div>
    <br />

    <div>(4) SDK名称：穿山甲广告SDK</div>
    <div>第三方公司名称：北京巨量引擎网络技术有限公司</div>
    <div>
      共享信息名称：
      设备标识信息（IMEI、AndroidID、OAID、IMSI、ICCID、GAID、MEID、Mac地址、IDFV、IDFA）、网络信息（IP地址、SSID）、应用特征信息（应用包名、版本号、运行状态）、移动应用列表信息、设备参数及系统信息（设备品牌、设备型号、操作系统版本、设备语言）、位置信息
    </div>
    <div>使用目的：用于广告推送</div>
    <div>使用场景：用户点击观看广告时</div>
    <div>共享方式：SDK本机采集</div>
    <div>
      第三方隐私链接：<a
        class="agreement"
        href="https://www.csjplatform.com/privacy"
        target="_Self"
        >https://www.csjplatform.com/privacy</a
      >
    </div>
    <br />

    <!-- <div>(7) SDK名称：百度AI图像审核SDK</div>
    <div>第三方公司名称：北京百度网讯科技有限公司</div>
    <div>共享信息名称：图像信息</div>
    <div>使用目的：判断用户使用的图像是否合规</div>
    <div>使用场景：用户上传头像或上传图片在社区时</div>
    <div>共享方式：SDK本机采集</div>
    <div>
      第三方隐私链接：<a
        class="agreement"
        href="https://ai.baidu.com/ai-doc/REFERENCE/Ckdym0tc9"
        target="_Self"
        >https://ai.baidu.com/ai-doc/REFERENCE/Ckdym0tc9</a
      >
    </div>
    <br /> -->

    <div>(5) SDK名称：巨量 SDK</div>
    <div>第三方公司名称：北京巨量引擎网络技术有限公司</div>
    <div>共享信息名称：设备标识信息</div>
    <div>使用目的：用户投放</div>
    <div>使用场景：向目标用户投放广告</div>
    <div>共享方式：SDK本机采集</div>
    <div>
      第三方隐私链接：<a
        class="agreement"
        href="https://www.oceanengine.com/extra/legal"
        target="_Self"
        >https://www.oceanengine.com/extra/legal</a
      >
    </div>
    <br />

    <div>(6) SDK名称：热云 SDK</div>
    <div>第三方公司名称：北京热云科技有限公司及其关联公司</div>
    <div>共享信息名称：设备标识信息、应用特征信息</div>
    <div>使用目的：用于用户归因</div>
    <div>使用场景：核对用户id</div>
    <div>共享方式：SDK本机采集</div>
    <div>
      第三方隐私链接：<a
        class="agreement"
        href="https://new.trackingio.com/#/policy"
        target="_Self"
        >https://new.trackingio.com/#/policy</a
      >
    </div>
    <br />

    <div>(7) SDK名称：数数科技 SDK</div>
    <div>第三方公司名称：数数信息科技(上海)有限公司</div>
    <div>
      共享信息名称：设备标识信息、网络信息、设备参数及系统信息、应用特征信息
      使用目的：第三方授权登录、内容分享
    </div>
    <div>使用目的：第三方授权登录、内容分享</div>
    <div>使用场景：用户使用微信账号授权登录时；用户分享内容至微信客户端时</div>
    <div>共享方式：SDK本机采集</div>
    <div>
      第三方隐私链接：<a
        class="agreement"
        href="https://docs.thinkingdata.cn/ta-manual/latest/installation/installation_menu/privacy.html#information-collection-and-use"
        target="_Self"
        >https://docs.thinkingdata.cn/ta-manual/latest/installation/installation_menu/privacy.html#information-collection-and-use</a
      >
    </div>
    <br />

    <div>(8) SDK名称：小米游戏联运SDK</div>
    <div>第三方公司名称：北京瓦力网络科技有限公司</div>
    <div>
      共享信息名称：设备标识【OAID、加密的Android
      ID、IMEI号(针对安卓P及之前)】、设备信息(设备厂商、型号、归属地、运营商名称等)、软件相关信息(Android系统和SDK版本号、Android
      ID、游戏服务版本号、wifi 的
      SSID/BSSID、MIUI版本号等)、个人信息【姓名和身份证号码(用于实名制、防沉迷功能)、第三方账号信息（用于实现登录账号）、游戏的ID、游戏包名、游戏版本号、游戏商品名称、商品金额和安装来源（用于实现支付功能）】
    </div>
    <div>
      可能调用的权限：访问网络状态、获取设备信息、写入设备存储、获取身体传感器信息、读取应用列表、相机
    </div>
    <div>使用目的：提供游戏账号登录、支付、实名制与防沉迷管理</div>
    <div>共享方式：SDK本机采集</div>
    <div>
      第三方隐私链接：<a
        class="agreement"
        href="https://dev.mi.com/distribute/doc/details?pId=1402"
        target="_Self"
        >https://dev.mi.com/distribute/doc/details?pId=1402</a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'listing',
  data () {
    return {}
  },
  mounted () {},
  methods: {
    agreement () {
      let routerData = this.$router.resolve({
        path: '/children'
      })
      window.open(routerData.href, '_blank')
    }
  }
}
</script>

<style lang="less" scoped>
.listing {
  word-break: break-all;
  word-wrap: break-word;
  padding-bottom: 20px;
  .title {
    text-align: center;
  }
  .bold {
    font-weight: bold;
  }
  .agreement {
    color: royalblue;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
